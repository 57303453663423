<div>
    <div class="page_header">
        <h1 class="page_title">Notification</h1>
    </div>

    <section class="search_section">
        <mat-card>
            <form [formGroup]="searchForm" (ngSubmit)="SearchRequest()">
                <mat-card-content>
                    <div class="search_container">
                        <div>
                            <div class="search_tile">
                                <span class="search_title">Title</span>
                                <!-- 언제 부터 언제 까지 휴가 검색 -->
                                <mat-form-field appearance="fill">
                                    <mat-label>Title</mat-label>
                                    <input matInput formControlName="title" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div>
                            <div class="search_tile">
                                <span class="search_title">category</span>
                                <!-- 상태  -->
                                <mat-form-field appearance="fill">
                                    <mat-label>Category</mat-label>
                                    <mat-select formControlName="category">
                                        <mat-option value="">All</mat-option>
                                        <mat-option value="Notice">Notice</mat-option>
                                        <mat-option value="Meeting">Meeting</mat-option>
                                        <mat-option value="Pay">Pay</mat-option>
                                        <mat-option value="Policy">Policy</mat-option>
                                        <mat-option value="Issue">Issue</mat-option>
                                        <mat-option value="Etc">Etc</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions align="end">
                    <button mat-flat-button color="primary" type="submit">SEARCH</button>
                </mat-card-actions>
            </form>
        </mat-card>
    </section>

    <section class="table_section mat-elevation-z1">
        <div class="example-table-container">
            <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
                    API rate limit has been reached. It will be reset in one minute.
                </div>
            </div>
            <div class="example-table-container">
                <table mat-table [dataSource]="data" class="" matSort matSortActive="updatedAt" matSortDisableClear
                    matSortDirection="desc">
                    <!-- createdAt -->
                    <ng-container matColumnDef="updatedAt">
                        <th mat-header-cell *matHeaderCellDef disableClear mat-sort-header>
                            Uploaded Date
                        </th>
                        <td mat-cell *matCellDef="let row">{{row.updatedAt | date }}
                        </td>
                    </ng-container>


                    <!-- title -->
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>Title</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.title}}
                        </td>
                    </ng-container>


                    <!-- category -->
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef>Category</th>
                        <td mat-cell *matCellDef="let row">{{row.category}}</td>
                    </ng-container>

                    <!-- Taken/RD -->
                    <ng-container matColumnDef="writer">
                        <th mat-header-cell *matHeaderCellDef>Writer</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.writer.username}}
                        </td>
                    </ng-container>


                    <!-- detail -->
                    <ng-container matColumnDef="detail">
                        <th mat-header-cell *matHeaderCellDef>Detail</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-flat-button color="primary" (click)="openDetailDialog(row)">detail</button>
                        </td>
                    </ng-container>
                    <!-- detail -->
                    <!-- <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef>Note</th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row.updator">Revised in {{row.updatedAt | date}}</span>
                        </td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


                    <!-- Row shown when there is no matching data that will be provided to the wrapper table. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="5">No data</td>
                    </tr>
                </table>
            </div>
            <mat-paginator [length]="resultsLength" (page)="handlePageEvent()" [pageSize]="10"
                [pageSizeOptions]="[10, 30, 50]" aria-label="Select page of GitHub search results"></mat-paginator>
        </div>
    </section>
</div>