<div class="dialog_container">
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 30px;">
        <div>
            <mat-icon class="ok_icon">check_circle</mat-icon>
        </div>

        <h1 class="making_sure">
            Awesome!
        </h1>

        <span class="confirm_content">
            {{ data.content }}
        </span>

        <div class="ok_button_box" style="display: flex; flex-direction: column;">
            <button mat-raised-button class="ok_button" (click)="closeModal()">OK</button>
        </div>
    </div>
</div>