import { Component } from '@angular/core';

@Component({
  selector: 'app-create-space-dialog',
  templateUrl: './create-space-dialog.component.html',
  styleUrls: ['./create-space-dialog.component.scss']
})
export class CreateSpaceDialogComponent {

}
