<div class="card_layout">
  <!-- 프로필 정보 -->
  <div>
    <mat-card>
      <div class="full-loading-shade" *ngIf="isStatusLoading">
        <mat-spinner></mat-spinner>
      </div>
      <mat-card-header style="display: flex; justify-content: space-between">
        <mat-card-title>Profile</mat-card-title>
        <mat-chip
          color="primary"
          *ngIf="info?.manager?.isSuperManager"
          class="chip"
        >
          <p style="margin: 0; font-size: 8pt">super</p>
        </mat-chip>
      </mat-card-header>
      <mat-card-content>
        <div style="display: flex" class="card_content">
          <!-- 이미지 들어갈 자리 -->
          <section style="padding: 8px; padding-right: 20px">
            <img
              class="profile_image"
              [src]="info?.manager.profileImgPath"
              alt=""
            />
          </section>
          <!-- 내용들 들어갈 자리 -->
          <section
            style="
              flex: 1;
              display: flex;
              justify-content: center;
              flex-direction: column;
              gap: 3px;
            "
          >
            <div class="info_line">
              Name:
              <p>{{ info?.manager?.username }}</p>
            </div>
            <div class="info_line">
              Email:
              <p>{{ info?.manager?.email }}</p>
            </div>
            <div class="info_line">
              Company:
              <p>{{ info?.manager?.companyId.companyName }}</p>
            </div>
            <div class="info_line">
              Phone:
              <p>{{ info?.manager?.phoneNumber }}</p>
            </div>
            <div class="info_line">
              Address:
              <p>{{ info?.manager?.address }}</p>
            </div>
          </section>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- 멤버 수 -->
  <!-- 누르면 employee list로 -->
  <div>
    <mat-card>
      <div class="full-loading-shade" *ngIf="isStatusLoading">
        <mat-spinner></mat-spinner>
      </div>
      <mat-card-header>
        <mat-card-title>My Member</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="dashboard_number card_content">
          {{ info?.memberCount }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- 휴가 요청 정보 -->
  <!-- 누르면 employee leave status로 -->
  <div
    *ngIf="
      userProfileData() && userProfileData().profileData?.user.isAnnual == true
    "
  >
    <mat-card>
      <div class="full-loading-shade" *ngIf="isStatusLoading">
        <mat-spinner></mat-spinner>
      </div>
      <mat-card-header>
        <mat-card-title>Leave Request</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="dashboard_number card_content">
          {{ info?.leaveRequests }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- 대체 휴가 요청 정보 -->
  <!-- 누르면 rd confirm request로 -->
  <div
    *ngIf="
      userProfileData() && userProfileData().profileData?.user.isAnnual == true
    "
  >
    <mat-card>
      <div class="full-loading-shade" *ngIf="isStatusLoading">
        <mat-spinner></mat-spinner>
      </div>
      <mat-card-header>
        <mat-card-title>RD Request</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="dashboard_number card_content">
          {{ info?.rdRequest }}
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
