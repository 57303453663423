<h1 mat-dialog-title>Change Password</h1>
<form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content style="display: flex; flex-direction: column;">
        <mat-form-field>
            <mat-label>New Password</mat-label>
            <input type="password" matInput formControlName="password" autocomplete="password">
            <mat-error *ngIf="passwordForm.controls['password'].hasError('required')">Password is
                <strong>required</strong></mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Confirm Password</mat-label>
            <input type="password" matInput formControlName="confirmPassword" autocomplete="password">
            <mat-error *ngIf="passwordForm.controls['password'].hasError('required')">ConfirmPassword is
                <strong>required</strong></mat-error>
        </mat-form-field>
        <mat-error class="cross-validation-error-message alert alert-danger"
            *ngIf="passwordForm.errors?.['PasswordNoMatch'] && (passwordForm.touched || passwordForm.dirty)">
            password does not match
        </mat-error>
    </div>

    <div mat-dialog-actions style="display: flex; justify-content: space-between;">
        <button mat-button mat-dialog-close color="accent">close</button>
        <button [disabled]="!passwordForm.valid" mat-flat-button color="primary">save</button>
    </div>
</form>