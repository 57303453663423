import { Component } from '@angular/core';

@Component({
  selector: 'app-find-password',
  standalone: true,
  templateUrl: './find-password.component.html',
  styleUrls: ['./find-password.component.scss']
})
export class FindPasswordComponent {

}
