<div class="layout">
    <mat-card class="mat_card">
        <mat-card-header class="mat_card_header">
            <section>
                <h1>GLOBAL HR KOREA</h1>
            </section>
            <section>
                <mat-card-title>
                    Welcome
                </mat-card-title>
            </section>
            <section>
                Sign in with your credentials below
            </section>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="signInForm" class="sign_in_form" (ngSubmit)="signIn()">
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" formControlName="email" placeholder="Ex. pat@example.com" name="email"
                        autocomplete="email" />
                    <mat-error
                        *ngIf="signInForm.controls['email'].hasError('email') && !signInForm.controls['email'].hasError('required')">Please
                        enter a valid email address</mat-error>
                    <mat-error *ngIf="signInForm.controls['email'].hasError('required')">Email is
                        <strong>required</strong></mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" name="password"
                        autocomplete="password" />
                    <mat-error
                        *ngIf="signInForm.controls['password'].hasError('minlength') || signInForm.controls['password'].hasError('maxlength') && !signInForm.controls['password'].hasError('required')">The
                        password should be between 6 and 15 characters</mat-error>
                    <mat-error *ngIf="signInForm.controls['password'].hasError('required')">Password is
                        <strong>required</strong></mat-error>
                </mat-form-field>
                <div style="margin-top: 1rem; display: flex; justify-content: space-between;">
                    <button mat-raised-button color="primary" type="submit" [disabled]="!signInForm.valid">SIGN
                        IN</button>

                    <section>
                        <mat-checkbox formControlName="isManager"><span
                                style="font-weight: bold;">MANAGER</span></mat-checkbox>
                    </section>
                </div>
            </form>
        </mat-card-content>
    </mat-card>

</div>