<div class="index_page">
    <section style="position: absolute; z-index: 10; width: 100%;">
        <mat-toolbar color="primary" class="toolbar mat-elevation-z6">
            <button mat-button>
                <h1>GLOBAL HR KOREA</h1>
            </button>

            <span style="flex: 1"></span>

            <div>
                <button mat-button [routerLink]="['/sign-in']">SIGN IN</button>
            </div>
        </mat-toolbar>
    </section>
    <section class="welcome">
        <h1>START GLOBAL HR KOREA</h1>
        <button [routerLink]="['/sign-in']" mat-flat-button color="primary">go to signIn</button>
    </section>
</div>