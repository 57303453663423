<div class="side_nav">
    <div class="side_nav_toolbar">
        <h1 style="color:rgba(63, 81, 181, 255); font-weight: bold;">GLOBAL HR KOREA</h1>
    </div>

    <div class="side_nav_items">
        <section class="scroll">
            <sidenav-item *ngFor="let item of navItems()" [item]="item" [level]="0"></sidenav-item>
        </section>
    </div>
</div>